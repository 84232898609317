<template>
  <div class="module-five list-scroll" v-infinite-scroll="loadMore">
    <template v-if="list.length > 0">
      <div class="list-item" v-for="l in list" :key="l.id">
        <img class="bg" :src="baseUrl + (l.img && l.img.file_path)" alt="" />
        <div class="list-item-content">
          <div class="list-item-left" v-html="formatterDate(l.time)"></div>
          <div class="list-item-middle">
            <div class="item-name scroll-hidden">{{ l.name || "无" }}</div>
            <div class="item-time">
              <i class="el-icon-time"></i>{{ l.time }}
            </div>
            <div class="item-mes list-scroll">{{ l.mes || "无" }}</div>
          </div>
          <div class="list-item-right">
            <span @click="toDetail(l)">查看<br />详情</span>
            <!-- <template v-if="l.page_customized === 'DiscussTheIndustry'">
              <span @click="toDetail(l)">注册<br />报名</span>
            </template>
            <template v-else>
              <span @click="toDetail(l)">查看<br />详情</span>
            </template> -->
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="empty-box">
        <img src="@/assets/images/empty.jpg" alt="" />
        <p>暂无数据</p>
      </div>
    </template>
  </div>
</template>

<script>
import { getModuleBaseList } from "@/api/base.js";
export default {
  name: "ModuleFive",
  data() {
    return {
      loadingList: false,
      searchObj: {
        page: 1,
        pageSize: 20,
        moduleCode: "",
        typeId: 0,
      },
      list: [],
      total: 0,
    };
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(val) {
        // console.log("val", val);
        const { query } = val;
        this.searchObj.moduleCode = query.module_code;
        this.searchObj.typeId = query.id;
      },
    },
    "searchObj.typeId"() {
      this.getModuleBaseList();
    },
  },
  created() {
    // console.log("created", this.searchObj);
    this.getModuleBaseList();
  },
  methods: {
    getModuleBaseList(loadMore = false) {
      if (!loadMore) {
        this.searchObj.page = 1;
        this.list = [];
      }
      this.loadingList = true;
      let params = {
        pageindex: this.searchObj.page,
        pagesize: this.searchObj.pageSize,
        module_code: this.searchObj.moduleCode,
        type_id: this.searchObj.typeId,
      };
      getModuleBaseList(params)
        .then((res) => {
          // console.log(res);
          if (res.code === 200) {
            const { data } = res;
            this.list = this.list.concat(data.data);
            this.total = data.dataCount;
          }
        })
        .finally(() => {
          this.loadingList = false;
        });
    },
    loadMore() {
      if (this.list.length < this.searchObj.page * this.searchObj.pageSize) {
        return;
      }
      this.searchObj.page += 1;
      this.getModuleBaseList(true);
    },
    toDetail(item) {
      this.$emit("clickItem", item.name);

      // if (item.page_customized === "DiscussTheIndustry") {
      // this.$router.push({
      //   name: "FormTwo",
      //   query: {
      //     module_code: this.searchObj.moduleCode,
      //     id: this.searchObj.typeId,
      //     cid: item.id,
      //   },
      // });
      // } else {
        this.$router.push({
          name: "DetailOne",
          query: {
            module_code: this.searchObj.moduleCode,
            id: this.searchObj.typeId,
            cid: item.id,
          },
        });
      // }
    },
    formatterDate(date) {
      date = date.split(" ")[0];
      let dateArr = date.split("-");
      // 03月<br />16日
      return dateArr[1] + "月<br/>" + dateArr[2] + "日";
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  .module-five {
    .list-item {
      margin-top: 20px;

      &:last-child {
        margin-bottom: 20px;
      }
      .list-item-left {
        font-size: 30px;
      }

      .list-item-middle {
        .item-name {
        }
        .item-mes {
        }
      }

      .list-item-right {
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .module-five {
    .list-item {
      margin-top: 10px;

      &:last-child {
        margin-bottom: 10px;
      }

      .list-item-left {
        font-size: 20px;
      }

      .list-item-middle {
        .item-name {
        }
        .item-mes {
        }
      }

      .list-item-right {
      }
    }
  }
}
.module-five {
  overflow-y: auto;
  .list-item {
    width: 100%;
    height: 170px;
    border: 1px solid #e4e4e4;
    position: relative;

    .bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }

    .list-item-content {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      // color: #fff;
      color: #2c3e50;
      display: flex;
      align-items: center;
      padding: 14px 0;
      background: rgba(255, 255, 255, 0.62);
    }

    .list-item-left {
      width: 16%;
      text-align: center;
      font-family: SimSun;
      font-weight: 400;
      // color: #ffffff;
      color: #2c3e50;
    }

    .list-item-middle {
      width: 68%;
      height: 100%;
      // border-left: 2px solid #fff;
      border-left: 2px solid #2c3e50;
      padding: 0 10px;

      .item-name {
        height: 20%;
        font-size: 16px;
        font-family: SourceHanSansCN;
        font-weight: bold;
        white-space: nowrap;
        overflow-x: auto;
      }

      .item-time {
        height: 16%;
        font-size: 14px;
        font-family: SourceHanSansCN;
        font-weight: bold;

        .el-icon-time {
          margin-right: 1%;
        }
      }

      .item-mes {
        height: 60%;
        text-indent: 2em;
        word-break: break-all;
        font-size: 14px;
        font-family: SourceHanSansCN;
        font-weight: 400;
        overflow: auto;
      }
    }

    .list-item-right {
      width: 16%;
      text-align: center;

      span {
        display: inline-block;
        // width: 38px;
        // height: 38px;
        background: #fff;
        border-radius: 50%;
        padding: 10px;
        font-size: 14px;
        font-family: SimSun;
        font-weight: 400;
        color: #007cf9;
        cursor: pointer;
      }
    }
  }
}
</style>